import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';
import logo from "../../assets/images/logo1.png"
import Image from "gatsby-image";
import { StaticImage } from "gatsby-plugin-image"
import { graphql,useStaticQuery } from 'gatsby';
const Header = () => {
  const data = useStaticQuery(graphql`
  query MyQuery {
    site {
      siteMetadata {
        Office
        Fax      
        Cell
      }
    }
  }
  `)
  const {Office,Fax,Email,Cell} = data.site.siteMetadata;
  console.log(Office,Fax,Email,Cell);
  return(
    <header className="sticky top-0 bg-gray-900 shadow border-b-2 border-white">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex-col md:flex-row hidden md:flex justify-between items-center">
        <StaticImage src="../../assets/images/logo.png" alt="A dinosaur" style={{height:83,width:196}} /> 
      </div>   
      <nav className="flex flex-col sm:flex-row items-center mt-4 sm:mt-0">
      <ul className='flex-col'>
          <li className="inline-block text-sm mr-2"><a href='tel:888-678-6284' className='text-white'>Office:{Office}</a></li>
          <li className="inline-block text-sm mr-2"><a href='tel:516-284-1330' className='text-white'>Cell:{Cell}</a></li>
          <li className="inline-block text-sm"><a href='tel:888-606-1005' className='text-white'>Fax:{Fax}</a></li>
        </ul>
      </nav>      
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4 text-white" href="#features">
        Services
        </AnchorLink>
        <AnchorLink className="px-4 text-white" href="#services">
          Features
        </AnchorLink>
        {/* <AnchorLink className="px-4" href="#stats">
          Stats
        </AnchorLink>
        <AnchorLink className="px-4" href="#testimonials">
          Testimonials
        </AnchorLink> */}
      </div>
      {/* <div className="hidden md:block">
        <Button className="text-sm">Start Free Trial</Button>
      </div> */}
    </div>
  </header>
  )
};


export default Header;



