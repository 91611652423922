import React from 'react';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {
  return (
    <header className="sticky top-0 bg-gray-900 shadow">
      <Header />
      <main className="text-gray-900">{children}</main>
      <Footer />
    </header>
  );
};

export default Layout;
