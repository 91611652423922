import React from 'react';
import { graphql,useStaticQuery } from 'gatsby';
const Footer = () => {
  const data = useStaticQuery(graphql`
  query FooterQuery {
    site {
      siteMetadata {
        Office     
        Cell
        Email
      }
    }
  }
  `)
  const {Office,Fax,Email,Cell} = data.site.siteMetadata;
  return(
    <footer className="container mx-auto py-16 px-3  text-gray-800">
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold text-white">About Us</h2>
        <p className="mt-5 text-white text-left">Our vision is to streamline the
        operational workflows, increase efficiency and optimize the goals of our
        clients and help them concentrate on their core business practices.
        </p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold text-white">Contacts</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="tel:888-678-6284" className='text-white'>Office: {Office}</a>
          </li>
          <li>
            <a href="tel:516-284-1330" className='text-white'>Cell: {Cell}</a>
          </li>
          <li>
            <a href="mailto:info@workflowoptimization.us" className='text-white'>Email: {Email}</a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold text-white">Services</h2>
        <ul className="mt-4 leading-loose">
          {/* <li>
            <a href="#" className='text-white'>Answering Service</a>
          </li> */}
          <li>
            <a href="#" className='text-white'>Answering Service</a>
          </li>
          <li>
            <a href="#" className='text-white'>Billing</a>
          </li>
          <li>
            <a href="#" className='text-white'>Credentialing</a>
          </li>
          <li>
            <a href="#" className='text-white'>Backoffice Services</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
  )
}
export default Footer;
